









































import Filters from '@/constant/Filters';
import { ProductSummaryModel } from '@/store/product/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ProductDetail',
  filters: { ...Filters }
})
export default class ProductDetail extends Vue {
  @Prop() summary!: ProductSummaryModel;
  @Prop({ type: Boolean, default: false }) hideTitle!: boolean;

  public created(): void {
    // on componenet created
  }
  public mounted(): void {
    // on componenet mounted
  }
}
